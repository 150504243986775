import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import Herobanner from "../components/herobanner";
import Container from "../components/containers/container";
import Box from "../components/containers/box";

const PrivacyPage = () => (
  <Layout>
    <Helmet>
      <title>Privacy Policy</title>
      <meta
        name='description'
        content="Offriamo soluzioni per l'assistenza da remoto e per il telemonitoraggio in continuo degli impianti. I sistemi che utilizziamo prevedono connessioni Internet in modalità sicura, tramite VPN."
      />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='' />
      <meta property='og:image' content='/image/social/og-teleassistenza-elettronica-scarpa.png' />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content='' />
      <meta property='twitter:title' content='Privacy Policy | Elettronica scarpa' />
      <meta
        property='twitter:description'
        content="Offriamo soluzioni per l'assistenza da remoto e per il telemonitoraggio in continuo degli impianti. I sistemi che utilizziamo prevedono connessioni Internet in modalità sicura, tramite VPN."
      />
      <meta property='twitter:image' content='/image/social/og-teleassistenza-elettronica-scarpa.png' />
    </Helmet>
    <Herobanner background='assistenza'>
      <h1 className='mb-6'>Cookie Policy</h1>
    </Herobanner>

    <Container notAligned={true}>
      <Box>
        <div className='row'>
          <div className='col-12 col-lg-10 offset-lg-1'>
            <h3 className='h5'>1 - Definizioni, caratteristiche e applicazione della normativa in materia di cookie</h3>
            <br />
            <p>
              I cookie sono piccoli file di testo che i siti visitati dall’utente inviano e registrano sul suo computer o dispositivo mobile, per essere poi ritrasmessi agli stessi siti alla
              successiva visita. Proprio grazie ai cookie un sito ricorda le azioni e preferenze dell’utente (come, ad esempio, i dati di login, la lingua prescelta, le dimensioni dei caratteri, altre
              impostazioni di visualizzazione, ecc.) in modo che non debbano essere indicate nuovamente quando l’utente torni a visitare detto sito o navighi da una pagina all’altra di esso. I cookie,
              quindi, sono usati per eseguire autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di informazioni riguardanti le attività degli utenti che accedono ad un sito e
              possono contenere anche un codice identificativo unico che consente di tenere traccia della navigazione dell’utente all’interno del sito stesso per finalità statistiche o pubblicitarie.
              Nel corso della navigazione su un sito, l’utente può ricevere sul suo computer o dispositivo mobile anche cookie di siti o di web server diversi da quello che sta visitando (c.d. cookie
              di “terze parti”). Alcune operazioni non potrebbero essere compiute senza l’uso dei cookie, che in certi casi sono quindi tecnicamente necessari per lo stesso funzionamento del sito.
              <br />
              Esistono vari tipi di cookie, a seconda delle loro caratteristiche e funzioni, e questi possono rimanere nel computer o dispositivo mobile dell’utente per periodi di tempo diversi: c.d.
              cookie di sessione, che viene automaticamente cancellato alla chiusura del browser; c.d. cookie persistenti, che permangono sull’apparecchiatura dell’utente fino ad una scadenza
              prestabilita.
            </p>
            <br />
            <h3 className='h5'>2 - Tipologie di cookie utilizzate dal Sito</h3>
            <br />
            <p>
              Il Sito utilizza cookie solo tecnici per garantire e agevolare la navigazione e fruizione del sito web e per raccogliere informazioni, in forma aggregata, sulle visite al Sito, rispetto
              ai quali non è richiesto alcun consenso da parte dell’interessato ai sensi della normativa in vigore. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere
              personale, né vengono utilizzati c.d. cookies persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti. L’uso di c.d. cookies di sessione (che non vengono memorizzati
              in modo persistente sul computer dell’utente e svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri
              casuali generati dal server) necessari per consentire l’esplorazione sicura ed efficiente del sito. I c.d. cookies di sessione utilizzati in questo sito evitano il ricorso ad altre
              tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l’acquisizione di dati personali identificativi dell’utente. Più
              precisamente il Sito utilizza:
            </p>
            <br />
            <h4 className='h6'>Cookies tecnici strettamente necessari</h4>
            <p>
              Questi cookie sono essenziali per portare a termine attività richieste dall'Utente. Per esempio, per memorizzare informazioni fornite da quest'ultimo mentre naviga nel sito o per gestire
              lo stato di "login" durante la visita.
            </p>
            <br />
            <h4 className='h6'>Cookie tecnici funzionali</h4>
            <p>
              Questi cookie permettono al sito di memorizzare scelte effettuate dall'utente, successivamente riutilizzabili. Per esempio permettono al sito di memorizzare le impostazioni di ricerca,
              l'autenticazione e altre funzioni personalizzate.
            </p>
            <br />
            <h4 className='h6'>Cookie di terzi</h4>
            <p>
              Il Sito NON utilizza direttamente alcun cookie di profilazione. Tuttavia possiede oggetti incorporati che scaricano cookies di terzi, spesso in grado di effettuare una profilazione degli
              interessi, delle ricerche o della navigazione dell'utente, per i quali di seguito trovate i links di dettaglio per informazioni e per gestirne il consenso.
            </p>
            <br />
            <h3 className='h5'>3 - Disabilitazione dei cookie</h3>
            <br />
            <p>
              Fermo restando quanto sopra indicato in ordine ai cookie strettamente necessari alla navigazione, l’Utente può eliminare gli altri cookie attraverso la funzionalità a tal fine messa a
              disposizione dal Titolare tramite la presente informativa oppure direttamente tramite il proprio browser.
              <br />
              La disattivazione dei cookie di terzi è inoltre possibile attraverso le modalità rese disponibili direttamente dalla società terza titolare di detto trattamento, come indicato ai link
              riportati nel paragrafo “cookie di terze parti”. <strong>Attenzione:</strong> la disabilitazione totale o parziale dei cookie tecnici può compromettere l’utilizzo delle funzionalità del
              Sito riservate agli utenti registrati. Al contrario, la fruibilità dei contenuti pubblici è possibile anche disabilitando completamente i cookie.
            </p>
            <br />
            <hr className='e-spacer py-4' />
            <hr className='e-spacer py-4' />
            <hr className='e-spacer py-4' />
            <hr className='e-spacer py-4' />
          </div>
        </div>
      </Box>
    </Container>
  </Layout>
);

export default PrivacyPage;
